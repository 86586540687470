import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import FullContainer from "../components/common/FullContainer"
import courses from "../data/courses"

const SkillsContainer = styled.div`
  @media (max-width: 600px) {
    margin-left: 1em;
    margin-right: 1em;

    h2 {
      font-size: 2px;
    }

    li {
      font-size: 16px;
    }
  }

  ul {
    list-style: none;
  }

  a {
    cursor: pointer;
    color: blue;
  }
`

const Courses = () => {
  return (
    <>
      <Layout>
        <FullContainer>
          <h1>Best Courses</h1>
          <SkillsContainer>
            <ul>
              <li>
                <a href={courses.udemyWebDevelopment}>
                  Udemy's Most Comprehensive Web Development Course
                </a>
              </li>
              <li>
                <a href={courses.udemyJavaScript}>
                  Udemy's Most Comprehensive JavaScript Course
                </a>
              </li>
            </ul>
          </SkillsContainer>
        </FullContainer>
      </Layout>
    </>
  )
}

export default Courses
